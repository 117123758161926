
import toast from "react-hot-toast";


const color = {
  'error' : 'red',
  'success' : 'green'
}




export function showToast(type,message,duration = 5000 ) {
  let style = {
    border: `1px solid ${color[type]}`,
    padding: '.5rem',
    color: '#000',
  }
  if(type===null) {
    toast(message, {
      icon: '',
      style: {
        ...style,
        color : '#fff',
        fontWeight : '200',
        background : 'rgb(76 0 255 / 50%)'
      },
      position: "bottom-center"
    });
  } else if(type === 'info') {
    toast(message, {
      icon: '✨',
      duration : duration,
      style: {
        ...style,
        color : '#000',
        fontWeight : '400',
        background : '#fff'
      },
      position: "top-right"
    });
  } else if(type === 'custorm') {

  } else {
    toast[type](message , {
      duration : duration,
      style: style,
      position: "top-right"
    });
  }
}

export function generateRandomRoomName(type = "roomName") {
  // Define the character pool for the room name
  const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  // Set the desired room name length
  const roomNameLength = type === 'password' ? 8 : 4;

  // Create an empty string to store the room name
  
  let roomName = type === 'password' ? "" : "room_";

  // Loop to generate random characters and build the room name
  for (let i = 0; i < roomNameLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    roomName += characters.charAt(randomIndex);
  }

  // Return the generated room name
  return roomName; 
}

export function generateRoomCode(length) {
  const digits = '0123456789';
  let code = '';
  for (let i = 0; i < length; i++) {
    code += digits[Math.floor(Math.random() * 10)];
  }
  return code;
}

export function getTime(time) {
  if(time === 0)  {
    return {
      minute : 0,
      second : 0,
    };
  }
  let minute = Math.floor((time / 1000 / 60) % 60);
  let second = (time / 1000) % 60;
  return {
    minute,
    second,
  };
}

export function generateRandomUserId(length = 15) {
  // Characters to choose from
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let userId = '';
  
  // Generate a random string of the specified length
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    userId += characters[randomIndex];
  }
  
  return localStorage.getItem('userID');
}

