
/* eslint-disable react/prop-types */
import React, { memo, useCallback, useEffect, useState } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import Text from "../../../../components/Wrapper/Text";
import Button from "../../../../components/button/Button";
import playTExt from "../../../../assets/Play.svg";
import {
  OnPlayerList,
  SocketGameStart,
  raiseEvent,
} from "../../../../constants/socketKeys";
import {
  publish,
  subscribe,
  unsubscribe,
} from "../../../../CustomEvents/event";
import { useDispatch, useSelector } from "react-redux";


import Avatar from "../../../../assets/avatar.png";
import { socket } from "../../../../socket/socket";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {  showToast } from "../../../../utils";
import { setSessionStorageItem } from "../../../../utils/sessionStorageUtils";
import ShareButton from "../ShareButton";
import { RWebShare } from "react-web-share";
import Spinner from "../../../../components/Spinner/Spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function RoomCode({ code }) {
  return (
    <div
      title="click to copy"
      className="lg:w-7/12 text-center text-xl bg-[#ffc226] cursor-pointer border-none flex items-center justify-center gap-2 py-2 px-6 outline outline-[#673F15] rounded-sm text-[#552600] drop-shadow-lg font-bolder"
    >
      {code}
      <CopyToClipboard
        text={code}
        onCopy={() => showToast("success", "code coppied!")}
      >
        <svg
          width="23"
          height="23"
          fill="none"
          stroke="#552600"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="13" height="13" x="9" y="9" rx="2" ry="2"></rect>
          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
        </svg>
      </CopyToClipboard>
    </div>
  );
}

export const AddPlayer = ({ player }) => {
  //   // console.log("player", player);
  return (
    <div className="row-span-2 text-center w-16 h-16 lg:w-20 lg:h-20">
      <div className="hover:opacity-70 transition-all cursor-pointer bg-[#fff] px-2 py-4 rounded-md outline outline-2 outline-yellow-400  w-full h-full flex items-center justify-center">
        {player ? (
          <div className="w-12 h-12">
            <img src={Avatar} alt="Avatar" width="100%" />
          </div>
        ) : (
          <svg
            width="70"
            height="70"
            fill="#ffba24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6Zm1 8c0-2.66 5.33-4 8-4s8 1.34 8 4v2H7v-2Z"
              clipRule="evenodd"
            ></path>
          </svg>
        )}
      </div>
      <span className="text-white text-[10px]">{player?.name || null}</span>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
function PRoomPlayerModal({ roomCode, modalShow, onClose,setPlayers,players }) {
  const [loading , setLoading] = useState(false);
  const { gameObj,userData,roomProperties, singleLobbyData} =
  useSelector((state) => state.game);
  const [maxPlayers, setMaxPlayers] = useState(-1);
  const dispatch = useDispatch();
  const navigate = useHistory();
  // const [players,setPlayers] = useState([]);
  

  const getPlayersList = useCallback((res) => {
    const data = res.detail;
    console.log("players list", data);
    setLoading(false);
    const players = data.players?.filter(player => player.id !== socket.id);
    setPlayers(players);
    setMaxPlayers(data.maxPlayers);
   
   // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])
  
  useEffect(() => {
     setLoading(true);
     subscribe(OnPlayerList , getPlayersList);
     return () => { 
       unsubscribe(OnPlayerList,getPlayersList);
     }
  },[getPlayersList, roomCode]);

  //** grid cols */
  let gridCols = "grid-cols-3";
  if (maxPlayers !== -1) {
    gridCols = `grid-cols-${maxPlayers - 1 || singleLobbyData?.maxPlayers - 1}`;
  }

  const startGame = () => {
    if(maxPlayers-1  === players.length) {
     const startGameEvent = {
      roomName: roomCode,
      eventCode : 'START_GAME',
      sendingUser: socket.id,
     };

     //** clear out session */
     setSessionStorageItem("gameSession", null);
 
     // publis event
     publish(raiseEvent, startGameEvent);
    
    } else {
      showToast("error", "Can't start untill all player joines");
    }
  };


  useEffect(() => {
    socket.on(SocketGameStart, (data) => {
      console.log("start raise event found", data);
       let redirectUrl = `/ludo/game?roomName=${data.roomName}&uid=${userData?._id}&started=false`;
       navigate.push(redirectUrl, { replace: true });
    });
    return () => socket.off(SocketGameStart);
  },[navigate, userData?._id]);

  // console.log("players",gridCols,players)

  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "30%",
        height: "auto",
        maxHeight: "70%",
      }}
      title=""
      id="private-room-player-modal"
    >
      <div className="p-8 h-full  w-10/12 m-auto  flex flex-col justify-center items-center gap-5">
        {/* text */}
        <Text
          title="Your room code:"
          size="3xl"
          color="text-center text-white text-xl lg:text-3xl"
        />

        {/* room code */}
        <RoomCode code={roomCode} />

        {/* text */}
        {/* <Text
          title="Your room password:"
          size="3xl"
          color="text-center text-white"
        /> */}

        {/* room code */}
        {/* <RoomCode code={roomPassword} /> */}

        {/* text */}
        <Text
          title="Share this room code with friends and ask them to join"
          size="md"
          color="text-center text-white text-wrap"
        />

        <ShareButton roomCode={roomCode} />
        {/* players */}
        {
         loading ? <div className="flex justify-center">
          <Spinner />
         </div> : (
              <div
                className={`grid grid-rows-1 ${gridCols} justify-center ${
                  maxPlayers === 4 || maxPlayers === 2
                    ? "w-full"
                    : "lg:w-9/12"
                } gap-x-4 mt-5`}
              >
            
                    <>
                        {maxPlayers === 2 && (
                          <AddPlayer player={players[0] || null} />
                        )}
      
                        {maxPlayers === 3 && (
                          <>
                            <AddPlayer player={players[0] || null} />
                            <AddPlayer player={players[1] || null} />
                          </>
                        )}
                        
                        {maxPlayers === 4 && (
                          <>
                            <AddPlayer player={players[0] || null} />
                            <AddPlayer player={players[1] || null} />
                            <AddPlayer player={players[2] || null} />
                          </>
                        )}
                    </>
              
              </div>
         )
        }
       

        {userData?.isCreator && (
          <Button
            text={<img src={playTExt} alt={playTExt} className="m-auto" />}
            className="play py-3 text-sm md:text-xl lg:w-4/12 mt-4"
            handleClick={startGame}
          />
        )}
      </div>
    </ModalWrapper>
  );
}

export default memo(PRoomPlayerModal);
