/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Rodal from "rodal";



function WinLossModalWrapper(props) {
  const {looserPlayer} = props;
  console.log("looser",looserPlayer)
  return (
    <Rodal
      visible={props?.modalShow}
      enterAnimation="zoom"
      leaveAnimation="zoom"
      onClose={props?.onClose}
      customStyles={props?.customStyles}
      id={props?.id}
      //   {...props}
    >
      <div className="absolute -top-24 sm:-top-44 lg:-top-28 2xl:-top-40 left-0 win-assets">
        <img src={'https://res.cloudinary.com/db5iskq3x/image/upload/v1734086940/Ribbin.png'} alt={"rebbien"} className="ribbine scale-[1.1]"/>
        <img src={looserPlayer?.winner  ? 'https://res.cloudinary.com/db5iskq3x/image/upload/v1734086940/victory.svg' : 'https://res.cloudinary.com/db5iskq3x/image/upload/v1734086941/defeat.svg'} alt={"win-modal-images"} className="winDefetImg w-4/12 xl:w-5/12 w-48 absolute top-[30%] md:top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2"/>
        {/* <Text
          title={props?.ribbenText}
          color="text-[#ffca3a] text-4xl font-bolder absolute top-[40%] left-1/2 -translate-x-1/2 -translate-y-1/2"
        /> */}
      </div>
      {props?.children}
    </Rodal>
  );
}

export default WinLossModalWrapper;
