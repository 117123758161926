import React from "react";

function Button({ type="button", text, handleClick = () => {} ,className ="" , style= {} }) {
  return (
    <button style={style} type={type} onClick={handleClick} className={` bg-[#ffc226] border-none  py-2 px-6 outline outline-[#673F15] rounded-lg text-[#673F15] drop-shadow-lg font-bolder hover:scale-95 transition-all ${className}`}>
       <span>{text}</span>
    </button>
  );
}

export default Button;
