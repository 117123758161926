import React, { useCallback, useEffect, useState } from 'react'

import { memo } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import Text from "../../../../components/Wrapper/Text";
import ListItem from "../List/ListItem";
import { joinRoom, RoomListing } from "../../../../constants/socketKeys";

import {
  setGameObj,
  setSingleLobbyData,
} from "../../../../feature/slice/gameSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRandomeColorForTowPlayers } from "../../../../helper/game";
import { resetSessionStorage } from "../../../../utils/sessionStorageUtils";
import { publish } from '../../../../CustomEvents/event';
import {socket} from '../../../../socket/socket'
import {SOCKET_EVENTS} from '../../../../socket/keys'

// eslint-disable-next-line react/prop-types
function JoinRoomM({ modalShow, onClose, onOpenSelectPawn }) {
  
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [rooms, setRooms] = useState([])

 const RoomListing = useCallback((message) => {
   const {rooms} = message;
   console.log("ROOM LISTING", rooms);
   const filterList = rooms.filter(room => {
      const isOpen = room.isOpen;
      const isVisibile = room.isVisible;
      const gameType = room.gameType === 'Multiplayer'
      return isOpen && isVisibile && gameType;
   })
   setRooms(filterList)
 },[]) 
  
   
 useEffect(() => {
   if(modalShow) socket.emit(SOCKET_EVENTS.ROOM_LIST);
   socket.on(SOCKET_EVENTS.ROOM_LIST, RoomListing);
   return () => {
    socket.off(SOCKET_EVENTS.ROOM_LIST, RoomListing)
   }
 },[modalShow,RoomListing]) 

  

  const handleJoinRoom = (roomData) => {
    const roomId = roomData?.roomId;
    const joinRoomData = {
      roomId,
      playerName: "user00" + `${Math.floor(Math.random() * 100)}`,
    };

    // publish(joinRoom, joinRoomData);

    publish(joinRoom,joinRoomData);

    //** clear out session */
    resetSessionStorage();

  };

  // console.log("rooms?>>>", rooms);
  //** Handle Join Room */
  const handleJoin = (roomData) => {
    const maxPlayers = roomData?.maxPlayers;
    if (maxPlayers === 2) {
      handleJoinRoom(roomData);
    } else {
      onOpenSelectPawn();
      dispatch(setSingleLobbyData(roomData));
    }
    onClose();
  };

  return (
    <>
      <ModalWrapper
        visible={modalShow}
        onClose={onClose}
        customStyles={{
          width: "30%",
          height: "auto",
          maxHeight: "70%",
        }}
        title="Rooms"
        id="join-room-modal"
      >
        <div className=" h-5/6 overflow-y-auto">
          {rooms?.length === 0 && (
             <>
               <div className="h-5/6 grid place-items-center">
                <Text
                  title="There is no rooms available."
                  color="text-zinc-200 text-xl text-center"
                />
              </div>
             </>
          )}
          <>
          <ul>
            {rooms.length > 0 &&
              rooms.map((roomData, idx) => (
                <>
                  <ListItem
                  key={roomData?.roomId}
                  serial={idx}
                  isVisible={roomData?.isVisible}
                  isOpen={roomData?.isOpen}
                  name={roomData?.roomId}
                  players={roomData?.players.length}
                  onJoin={() => handleJoin(roomData)}
                  roomAmmount={roomData?.roomAmount}
                />
                </>
              ))}
          </ul>
          </>
        </div>
      </ModalWrapper>
    </>
  );
}

export default memo(JoinRoomM);
