/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useId, useMemo , useState } from "react";
import MainLogo from "../../components/Logo/MainLogo";
import OnlineMode from "../../assets/onlineMode.png";
import privateMode from "../../assets/privateMode.png";
import MultiplayerMode from "../../assets/MultiplayerMode.png";
import ComputerMode from "../../assets/ComputerMode.png";
import anime from "animejs";
import { useDispatch, useSelector } from "react-redux";
import { reset, setCurrentRoomPlayers, setCurrentRoomProperties, setGameMode, setGameObj, setIsCreated, setProperties, setSingleLobbyData, setUserData, setisConnected } from "../../feature/slice/gameSlice";
import SelectGameModal from "./components/Modals/SelectGameModal";
import CreateRoomM from "./components/Modals/CreateRoomM";
import JoinRoomM from "./components/Modals/LobbyM";
import SelectBoardM from "./components/Modals/SelectBoardM";
import WinLossModalWrapper from "../../components/Modal/WinLossModalWrapper";
import WinLossModal from "./components/Modals/WinLossModal";
import Img from 'react-cool-img';
import {
  LeaveRoom,
  OnConnectToMaster,
  OnLeaveRoom,
  OnRoomListing,
  RoomListing,
  SocketgetRoomProperties,
  SocketjoinRoom,
  getRoomProperties,
  joinRoom,
  onCreateRoom,
  onGetRoomProperties,
  onJoinRoomFailed,
  onRaiseEvent,
  onJoinRoom,
  PlayerList,
} from "../../constants/socketKeys";
import { publish, subscribe, unsubscribe } from "../../CustomEvents/event";
import { socket } from "../../socket/socket";
import SelectPawns from "./components/Modals/SelectPawns";
import SelectGameTypeM from "./components/Modals/SelectGameTypeM";
import JoinPrivateRoomModal from "./components/Modals/JoinPrivateRoomModal";
import PRoomPlayerModal from "./components/Modals/PRoomPlayerModal";
import { delay, generateRandomRoomName, getRandomColorByExistingColor, showToast } from "../../utils";
import { setSessionStorageItem } from "../../utils/sessionStorageUtils";
import { useHistory } from "react-router-dom";
import rng from '../../assets/RNG.svg'
import QuickTypeModal from "./components/Modals/QuickTypeModal";
import { getRandomeColorForTowPlayers } from "../../helper/game";
import history from "history-events";
import useVisibilityChange from "../../hooks/useVisibilityChange";
import Spinner from "../../components/Spinner/Spinner";
import { gameSound } from "../../../../../App";
import { JoinPoolApi } from "../../feature/service/gameService";
import { AuthContext } from '../../../../../context/Auth';
import Text from "../../components/Wrapper/Text";
import { replace } from "lodash";
import { SOCKET_EVENTS } from "../../socket/keys";
import { encodeData } from "../../helper/deCompressData";
import { GAME_STATUS } from "../../constants";

const RngLogo = () => {
   return (
     <div className="w-[3rem] sm:w-20 absolute right-5 top-24">
        <img src={rng} alt="rngLogo" width="100%"/>
     </div>
   )
}

function MainMenu() {
  const [modes, setModes] = useState([
    {
      id: 1,
      name: "Private",
      title: "Private",
      img: 'https://res.cloudinary.com/db5iskq3x/image/upload/v1734086940/privateMode.png',
      className: "mt-10",
    },
    {
      id: 2,
      name: "Multiplayer",
      title: "Multiplayer",
      img: 'https://res.cloudinary.com/db5iskq3x/image/upload/v1734086942/MultiplayerMode.png',
      className: "mt-10",
    },
    {
      id: 3,
      name: "Computer",
      title : 'Free Play',
      img: 'https://res.cloudinary.com/db5iskq3x/image/upload/v1734086940/ComputerMode.png',
      className: "mt-10 lg:mt-8",
    },
  ]);
  const [gameType, setGameType] = useState([
    {
      id: generateRandomRoomName(),
      name: "Classic",
      selected : true
    },
    {
      id: generateRandomRoomName(),
      name: "Quick Play",
      selected : false
    },
  ]);
  const [modalShow, setModalShow] = useState(false);
  const [createMultiplayerModalShow, setRoomModal] = useState(false);
  const [joinRoomModalShow, setJoinRoomModal] = useState(false);
  const [joinPRoomModalShow, setJoinPRoomModal] = useState(false);
  const [selectBoardModal, setBoardModal] = useState(false);
  const [selectPawnModal, setSelectPawnModal] = useState(false);
  const [selectGameTypeModal, setSelectGameTypeModal] = useState(false);
  const [joinPlayerModalShow, setJoinPlayerModalShow] = useState(false);
  const [openQuickPlayModal,setopenQuickPlayModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useHistory();
  const {gameObj,gameMode,userData} = useSelector(state => state.game);
  const [roomCode,setRoomCode] = useState(null);
  const [roomPassword,setRoomPassword] = useState(null);
  const [roomName,setRoomName] = useState(null); 
  const [players, setPlayers] = useState([]);
  useVisibilityChange(joinPlayerModalShow);
  const [boardLoading, setLoading] = useState(true);
  const [isPending, setPending] = useState(false);
  const [isCreating, setisCreating] = useState(false);
  const [creating , setCreating] = useState(false);

  // ** auth context
  const auth = useContext(AuthContext)
  
  // animate modes
  useEffect(() => {
    if(boardLoading === false) {
      anime({
        targets: ".game-mode",
        scale: [0.5, 1],
        delay: anime.stagger(100), // increase delay by 100ms for each elements.
      });
    }
  }, [boardLoading]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setLoading(false);
    }, 1250);
    return () => clearTimeout(timeout);
  }, []);
  
  // disconnect and reconnect player
  useEffect(() => {
     if(joinPlayerModalShow === false && socket?.connected) {
       window.location.reload();
     }
  },[joinPlayerModalShow])


  // handle modal close
  const onClose = () => setModalShow(false);
  const onCloseCreateGameModal = () => {
      setRoomModal(false)
  };
  const onCloseJoinRoomModal = () => setJoinRoomModal(false);
  const onCloseJoinPRoomModal = () => setJoinPRoomModal(false);
  const onCloseSelectBoardModal = () => setBoardModal(false);
  const onCloseJoinPlayerModalShow = () => {
    if(roomCode) {
      publish(LeaveRoom, { roomname: roomName,userId : userData?._id });
    }else {
      showToast("error", "roomCode not found...");
    }
     setJoinPlayerModalShow(false)
  };
  const onCloseSelectGameTypeModal = () => {
    setSelectGameTypeModal(false)
  };

  // handle modal Opne
  const onOpenJoinRoomModal = () => {
    setJoinRoomModal(true);
    onCloseCreateGameModal(false);
  };

   // handle modal private modal Opne
   const onOpenJoinPRoomModal = () => {
    setJoinPRoomModal(true);
    onCloseCreateGameModal(false);
  };

  // handle create room modal Opne
  // const onOpenCreateRoomModal = () => {
  //   setModalShow(true);
  //   onCloseSelectGameTypeModal();
  // };

  // handle select board modal
  const onOpenSelectBoardModal = () => {
    setBoardModal(true);
    //  setRoomModal(false);
  };

  // handle select mode
  const handleSelectMode = (mode) => {

    dispatch(setGameMode(mode));

    if (mode.name === 'Private') {
      setRoomModal(true);
      // dispatch( setGameObj({
      //   token : 'green',
      //   inGamePlayer : 2,
      //   selectedBoard : 'default',
      //   feeAmmount : 0
      // }))
    } else if(mode.name === "Multiplayer") {
      setSelectGameTypeModal(true);
      // dispatch( setGameObj({
      //   token : 'green',
      //   inGamePlayer : 2,
      //   selectedBoard : 'default',
      //   feeAmmount : 0
      // }))
    } else {
      setSessionStorageItem('botGameSession', null);
      setModalShow(true);
      // window.open('https://freeplay.betbyheart.com/', '_self')
    }
    setSessionStorageItem('gameMode',  mode.name);
  };

  //** onConnectMaster */ */
  const onConnectMaster = useCallback(() => {
      dispatch(setisConnected(true));
  },[dispatch])
   
  //** onOnLeaveRoomHandle */
  const onOnLeaveRoomHandle = useCallback(() => {
      // const data = eventData.detail;
  },[]);

  //**onHandleJoinRoomFailed */
  const onHandleJoinRoomFailed = useCallback((msg) => {
      const data = msg.detail;
      if(data.message) {
         showToast("error", data.message);
      }
      setPending(false)
       // console.log("on join room handle", data);
  },[]);

  //** on handle room join */
  const onRoomJoinHandle = useCallback(
    (response) => {
      console.warn("onRoomJoinHandleresponse>>>>", encodeData(response.detail));
      const {message,game,isAlreadyJoin} = encodeData(response.detail);
      if(message === 'JOIN-GAME') {
        setPending(false);
        const roomId = game.roomId;
        const isVisible = game.isVisible;
        const players = game.players;
        const boardType = game.boardType;
        const gameType = game.gameType;
        const roomAmount = game?.roomAmount
        const status = game?.gameStatus;

        

        publish(PlayerList, { roomId: roomId });
        const getMe = players.find((player) => player._id.toString() === localStorage.getItem('userID'));

        if(!getMe) {
           alert('something went wrong..');
           return;
        }

        dispatch(setCurrentRoomPlayers(players));
        dispatch(setProperties({maxPlayers : game.maxPlayers}));
         
        const isPrivate = isVisible === false && gameType === "Private";
    
        if (isPrivate) {
          if(isAlreadyJoin && (status === GAME_STATUS?.waiting_for_dice || status === GAME_STATUS?.waiting_for_token)) {
            let redirectUrl = `/ludo/game?roomName=${roomId}&uid=${getMe?._id}&started=true`;
            navigate.push(redirectUrl);
          } else {
            onCloseJoinPRoomModal();
            setJoinPlayerModalShow(true);
          }
        }

        if(gameType === "Quick Play") {
          setCreating(false);
          setopenQuickPlayModal(false);
        }

        dispatch(
          setUserData({
            ...getMe,
            isCreator: game.creator === localStorage.getItem('userID'),
          })
        );

        dispatch(setGameObj({ token: getMe?.color, selectedBoard: boardType , feeAmmount : +roomAmount}));
        dispatch(setGameMode({name : gameType}));

        //* store local use into session storage */
        setSessionStorageItem("user", getMe);

        if((isVisible || gameType === "Quick Play") && roomId) { 
          if(isAlreadyJoin) {
            let redirectUrl = `/ludo/game?roomName=${roomId}&uid=${getMe?._id}&started=true`;
            navigate.push(redirectUrl);
          } else {
            navigate.push(`/ludo/gameEnter?room=${roomId}`);
          }
        }
      }
    },
    [dispatch]
  );

  const onHandleCreateRoom = useCallback((data) => {
    const parsedData =data.detail;
    console.log("onHandleCreateRoom",parsedData);
    if(!parsedData.roomId) {
       alert('room id not found');
       return;
    }

    setRoomName(parsedData.roomId);
    if(gameMode?.name === 'Private' || gameMode?.name === 'Multiplayer') {
      publish(joinRoom,{
        roomId: parsedData.roomId,
        playerName: 'user00' + Math.floor(Math.random() * 100),
        // roomPassword : formData?.roomPassword
        color : gameObj?.token,
        userId : localStorage.getItem('userID')
      });
    } 
    if(gameMode?.name === 'Multiplayer') {
      setTimeout(() => {
        setisCreating(false);
        // navigate.push(`/ludo/gameEnter?room=${parsedData.roomId}`);
      },1000)
    }
  },[gameMode?.name,navigate,gameObj?.token])


  // reset all state
  useEffect(() => {
    // console.log("joinPlayerModalShow>>>",joinPlayerModalShow)
     if(joinPlayerModalShow === false) {
        dispatch(reset(null));
        dispatch( setGameObj({
          token : 'green',
          inGamePlayer : 2,
          selectedBoard : 'default',
          feeAmmount : 1
        }));
        setPlayers([]);
     }
  },[dispatch,joinPlayerModalShow]);

  const onRoomJoinInfo = ({message}) => {
    showToast('info', message);
    setCreating(false);  
  }



  // TODO handling socket events
  useEffect(() => {
    subscribe(OnConnectToMaster, onConnectMaster);
    subscribe(onCreateRoom, onHandleCreateRoom);
    subscribe(onJoinRoom, onRoomJoinHandle);
    subscribe(onJoinRoomFailed, onHandleJoinRoomFailed);
    subscribe(OnLeaveRoom, onOnLeaveRoomHandle);
    socket.on(SOCKET_EVENTS.INFO, onRoomJoinInfo)
    return () => {
      unsubscribe(onJoinRoom, onRoomJoinHandle);
      unsubscribe(OnConnectToMaster, onConnectMaster);
      unsubscribe(onCreateRoom, onHandleCreateRoom);
      unsubscribe(OnLeaveRoom,onOnLeaveRoomHandle);
      socket.off(SOCKET_EVENTS.INFO, onRoomJoinInfo)
    };
  }, [dispatch, onConnectMaster, onHandleCreateRoom, onHandleJoinRoomFailed, onOnLeaveRoomHandle, onRoomJoinHandle]);
  



  // handlle create quick play game
  const handleQuickPlayGame = (quickPlayCB) => {
    quickPlayCB();
  }


  useEffect(() => {
    if (history.isHistorySupported()) {
      window.addEventListener('popstate', function(e) {
        gameSound.pauseBgAudio();
        navigate.push('/')
      });
    }
},[]);
  
  return (
    <div className="main-container flex flex-col justify-between h-dvh lg:h-auto md:gap-2 mt-[10rem] md:mt-[4rem] lg:mt-[2rem]  md:pt-0">
      <MainLogo />
      <RngLogo />
      {/* modes for large devices */}
      <div className="hidden game-modes game-modes-desktop  w-7/12 m-auto grid lg:grid-cols-3 gap-2 lg:flex">
        {boardLoading ? (
          <div className="w-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            {modes.map((mode) => (
              <div
                className="game-mode grid place-items-center cursor-pointer"
                key={mode?.name}
                onClick={() => handleSelectMode(mode)}
              >
                <img
                  src={mode.img}
                  alt={mode.name}
                  className={`${mode.className} cursor-pointer`}
                  draggable={false}
                 
                />
                <Text title={mode?.title} color={`absolute lg:text-md xl:text-2xl ${mode.name === 'Computer' ? 'bottom-[20%]' : 'bottom-[18%]'}`} />
              </div>
            ))}
          </>
        )}
      </div>

      {/* modes for large devices */}
      <div className="game-modes game-modes-mobile h-full lg:hidden">
        {boardLoading ? (
          <div className="w-full h-full mt-16 flex  items-center  justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex justify-center">
              <div
                className="game-mode w-64 md:w-80 grid place-items-center cursor-pointer"
                key={modes[0].name}
                onClick={() => handleSelectMode(modes[0])}
              >
                <Img
                  src={modes[0].img}
                  alt="React Cool Img"
                  className={`${modes[0].className} cursor-pointer`}
                  draggable={false}
                  
                />
                <Text title={modes[0]?.title} color={`absolute text-md md:text-xl lg:text-2xl bottom-[15%]`} />
              </div>
            </div>
            <div className="flex justify-center">
              {modes.map((mode, idx) => (
                <>
                  {idx !== 0 && (
                    <div
                      className="game-mode w-64 md:w-80 h-50 xl:h-80 grid place-items-center cursor-pointer"
                      key={mode.name}
                      onClick={() => handleSelectMode(mode)}
                    >
                      <img
                        src={mode.img}
                        alt={mode.name}
                        className={`${mode.className} cursor-pointer`}
                        draggable={false}
                       
                      />
                      <Text title={mode?.title} color={`absolute game-mode-${mode.name} text-md md:text-xl lg:text-2xl ${mode.name === 'Computer' ? 'bottom-[15%]' : 'bottom-[15%]'}`} />
                    </div>
                  )}
                </>
              ))}
            </div>
          </>
        )}
      </div>

      {/* create game modal */}
      <SelectGameModal
        modalShow={modalShow}
        onClose={onClose}
        onOpenSelectBoardModal={onOpenSelectBoardModal}
        balance={auth?.viewWalletData?.balance ? parseFloat(auth?.viewWalletData?.balance).toFixed(2) : '0'}
        isCreating={isCreating}
        setisCreating={setisCreating}
      />

      {/* crete room modal */}
      <CreateRoomM
        modalShow={createMultiplayerModalShow}
        onClose={onCloseCreateGameModal}
        onOpenJoinRoomModal={onOpenJoinRoomModal}
        onOpenJoinPRoomModal={onOpenJoinPRoomModal}
        onOpenCreateRoomModal={() => setModalShow(true)}
        onOpenSelectGameTypeModal={() => setSelectGameTypeModal(true)}
        setPending={setPending}
      />

      {/* join room modal */}
      <JoinRoomM
        modalShow={joinRoomModalShow}
        onClose={onCloseJoinRoomModal}
        onOpenSelectPawn={() => setSelectPawnModal(true)}
      />

      {/* board select Modal */}
      <SelectBoardM
        modalShow={selectBoardModal}
        onClose={onCloseSelectBoardModal}
        setModalShow={setModalShow}
        onOpenJoinPlayerModalShow={() => setJoinPlayerModalShow(true)}
        setRoomCode={setRoomCode}
        setRoomPassword={setRoomPassword}
      />

      {/* select pawn modals */}
      <SelectPawns
        modalShow={selectPawnModal}
        onClose={() => setSelectPawnModal(false)}
      />

      {/* SELECT GAME TYPE */}
      <SelectGameTypeM
        modalShow={selectGameTypeModal}
        onClose={onCloseSelectGameTypeModal}
        // onOpenCreateRoomModal={onOpenCreateRoomModal}
        onOpenSelectOptionModal={() => {
          setRoomModal(true);
        }}
        gameType={gameType}
        updateGameType={setGameType}
        setopenQuickPlayModal={setopenQuickPlayModal}
      />

      {/* join pvt modal */}
      <JoinPrivateRoomModal
        modalShow={joinPRoomModalShow}
        onClose={onCloseJoinPRoomModal}
        setRoomCode={setRoomCode}
        onOpenJoinPlayerModalShow={() => setJoinPlayerModalShow(true)}
        isPending={isPending}
        setPending={setPending}
        balance={auth?.viewWalletData?.balance ? parseFloat(auth?.viewWalletData?.balance).toFixed(2) : '0'}
      />

      {/* joined private room player modal */}
      <PRoomPlayerModal
        roomCode={roomCode}
        roomPassword={roomPassword}
        modalShow={joinPlayerModalShow}
        onClose={onCloseJoinPlayerModalShow}
        setPlayers={setPlayers}
        players={players}
        auth={auth}
      />

      <QuickTypeModal
        modalShow={openQuickPlayModal}
        onClose={() => {
          setopenQuickPlayModal(false)
          setCreating(false);  
        }}
        handleQuickPlayGame={handleQuickPlayGame}
        creating={creating}
        setCreating={setCreating}
        balance={auth?.viewWalletData?.balance ? parseFloat(auth?.viewWalletData?.balance).toFixed(2) : '0'}
      />
      {/**/}
      {/* <WinLossModal /> */}
    </div>
  );
}

export default MainMenu;
