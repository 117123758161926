import moment from "moment";
import { publish, subscribe } from "../CustomEvents/event";
import { LeaveRoom, OnLeaveRoom, OnLeaveRoomFailed, PlayerList, RoomListing, ScoketleaveRoom, SocketDiceroll, SocketEndTimer, SocketGameStart, SocketPlayerList, SocketRematchAccept, SocketRematchRequest, SocketRoomListing, SocketSendMessage, SocketUpdateMove, SocketchangeRoomBaseProperty, SocketcreateRoom, SocketgetRoomProperties, SocketjoinRoom, SocketraiseEvent, SocketreJoinRoom, SocketsetRoomProperties, createRoom, getRoomProperties, getRoomPropertiesForLobby, joinRoom, localGetRoomProperties, localSetRoomProperties, localchangeRoomBaseProperty, onLocalGetRoomProperties, raiseEvent, reJoinRoom, setRoomProperties, setRoomPropertiesForLobby } from "../constants/socketKeys";
import { sendRequest } from "./socket";
import { showToast } from "../utils";
import { SOCKET_EVENTS } from "./keys";

var baseRoom = {
    roomname: "",
    ttl: 0,//0,seconds,default=0
    isVisible: true,//true,false
    isOpen: true,//true,false
    maxPlayers: 0,//Any number,Defalut 10
    roomPassword: "",//Room Password only applicable if isvisible False
    nickname: ""
}

subscribe(createRoom, async (coreEventData) => {
    var roomData = coreEventData.detail;
    console.log("roomData",roomData);
    const unixTimestamp = moment().valueOf();
    // console.log('Unix Timestamp:', unixTimestamp);
    baseRoom.roomname = roomData.roomname || unixTimestamp
    baseRoom.maxPlayers = roomData.maxPlayers || 4;
    baseRoom.ttl = roomData.ttl || 0;
    baseRoom.isVisible = roomData.isVisible;
    baseRoom.isOpen = roomData.isOpen;
    baseRoom.roomPassword = roomData.roomPassword || '';
    baseRoom.nickname = roomData.nickname || ('Guest ' + unixTimestamp);

    roomData.userId = localStorage.getItem('userID');
    
    await sendRequest(SocketcreateRoom, roomData, (serverResponse) => {
        // console.log('sendRequest from server:', serverResponse);
        // publish(onCreateRoom, serverResponse);
    });
});

//** GET ROOM LISTING */
subscribe(RoomListing, async (coreEventData) => {
    // console.log("RoomListingcoreEventData",coreEventData)
    await sendRequest(SocketRoomListing, "", (serverResponse) => {
        // console.log('RoomListing from server:', serverResponse);
    });
});

//** ROOM JOIN */
subscribe(joinRoom, async (coreEventData) => {
    let JoinData = coreEventData.detail;
    const userName = localStorage.getItem('userName')
    console.log("ludo_joinRoom coreEventData",coreEventData)
    JoinData = {
     ...JoinData,
     playerName : userName,
     userId : localStorage.getItem('userID')
    }
    await sendRequest(SocketjoinRoom, JoinData, (serverResponse) => {
        console.log('RoomListing from server:', serverResponse);
    });
});

//** RE-JOIN ROOM */
subscribe(reJoinRoom, async (roomDataToJoin) => {
    // console.log("Sending Rejoin response ", roomDataToJoin.detail)
    if (!roomDataToJoin.detail.roomname) {
        return
    }
    if (!roomDataToJoin.detail.uid) {
        return
    }
    await sendRequest(SocketreJoinRoom, roomDataToJoin.detail, (serverResponse) => {
        // console.log("Rejoin response ", serverResponse)
    });
})


//** PLAYER LEAVE ROOM */
subscribe(LeaveRoom, async (coreEventData) => {
    const data = coreEventData.detail;
    if(! data.userId) {
    //   showToast('error', "userid not found while leaving room");
      return;
    }
    var leaveRoomData = {
        roomId: data.roomname,
        userId : data.userId
    }
    
    await sendRequest(SOCKET_EVENTS.LEAVE_GAME, leaveRoomData, (serverResponse) => {
        if (serverResponse.success === false) {
            publish(OnLeaveRoomFailed, serverResponse)
            return
        }
        publish(OnLeaveRoom,data);
    })
})

// ** SET Room Properties for lobby **
subscribe(localSetRoomProperties , async (eventData) => {
    var data = eventData.detail;
    // console.log("localSetRoomProperties",data)
    await sendRequest(setRoomPropertiesForLobby, data, (serverResponse) => {
        // console.log('localSetRoomProperties from server:', serverResponse);
    });
})


// ** GET Room Properties
subscribe(getRoomProperties , async (eventData) => {
    var data = eventData.detail;
    console.log("getRoomProperties",data);
    await sendRequest(SocketgetRoomProperties, data, (serverResponse) => {
        // console.log('localgetRoomProperties from server:', serverResponse);
    });
})

subscribe(localGetRoomProperties , async (eventData) => {
    var data = eventData.detail;
    // console.log("getRoomProperties",data);
    await sendRequest(getRoomPropertiesForLobby, data, (serverResponse) => {
        
        // console.log('localSetRoomProperties from server:', serverResponse);
    });
})


// ** SET Room Properties **
subscribe(setRoomProperties , async (eventData) => {
    var data = eventData.detail;
    // console.log("localSetRoomProperties",data)
    await sendRequest(SocketsetRoomProperties, data, (serverResponse) => {
        publish(onLocalGetRoomProperties,serverResponse)
        // console.log('localSetRoomProperties from server:', serverResponse);
    });
})
subscribe(localchangeRoomBaseProperty , async (eventData) => {
    var data = eventData.detail;
    // console.log("localchangeRoomBaseProperty",data)
    await sendRequest(SocketchangeRoomBaseProperty, data, (serverResponse) => {
        // publish(onLocalGetRoomProperties,serverResponse)
        // console.log('localSetRoomProperties from server:', serverResponse);
    });
})


//** GET Local Player listing */
subscribe(PlayerList , async (eventData) => {
    var data = eventData.detail;
    await sendRequest(SocketPlayerList, data, (serverResponse) => {
      // console.log('SocketPlayerList from server:', serverResponse);
    });
})


//** RAISE EVENT FOR */

    // Map event codes to their respective socket actions
    const eventSocketMap = {
        'START_GAME': SocketGameStart,
        'ROLL_DICE': SocketDiceroll,
        'UPDATE_MOVE': SocketUpdateMove,
        'TIMER:END': SocketEndTimer,
        'REMATCH:REQUEST': SocketRematchRequest,
        'REMATCH:ACCEPT': SocketRematchAccept,
        'SEND:MESSAGE': SocketSendMessage
      };
      

subscribe(raiseEvent, async (coreEventData) => {
    const shareData = coreEventData.detail;
   
    // console.log("toShareData>>>>",toShareData);
    if(shareData?.roomName === null) alert("You are sending room name null");



  // Check if the event code exists in the map
  const socketAction = eventSocketMap[shareData.eventCode];
  
  if (socketAction) {
    // Send the request if the event code is valid
    await sendRequest(socketAction, shareData);
  }

})