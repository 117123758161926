import React, { useEffect, useState } from "react";
import { gameSound } from "../../../../App";


function useVisibilityChange(joinPlayerModalShow = false) {
  const [visibile, setVisibile] = useState(false);
  const [online, setOnline] = useState(false);

  // ** player visibility change if player fous
  useEffect(() => {
    // check online ofline
    setOnline(window?.navigator.onLine);
    const onVisibility = () => {
      if (document.visibilityState === "hidden") {
        setVisibile(false);
        gameSound.pauseBgAudio();
      }  else if(joinPlayerModalShow === false) {
        window.location.reload();
      }
    };
    document.addEventListener("visibilitychange", onVisibility,false);
    return () => document.removeEventListener("visibilitychange", onVisibility,false);
  }, [joinPlayerModalShow]);
  return [visibile, online];
}

export default useVisibilityChange;
