/* eslint-disable react/prop-types */
import React from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import CardWrapper from "../CardWrapper";
import CImage from "../../../../components/Img/CImage";

// image
import createPng from "../../../../assets/createPng.png";
import joinPng from "../../../../assets/joinPng.png";
import Text from "../../../../components/Wrapper/Text";
import Button from "../../../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../../../feature/slice/gameSlice";

// eslint-disable-next-line react/prop-types
function CreateRoomM({
  modalShow,
  onClose,
  onOpenJoinRoomModal,
  onOpenCreateRoomModal,
  onOpenSelectGameTypeModal,
  onOpenJoinPRoomModal,
  setPending
}) {
  const { gameMode } = useSelector((state) => state.game);
  const dispatch =  useDispatch();
  const open = () => {
    onOpenCreateRoomModal();
    onClose();
     dispatch(reset())
  };

  const handleJoinButton = () => {
    if (gameMode?.name === "Private") {
      onOpenJoinPRoomModal();
      dispatch(reset())
      setPending(false);
    } else {
      onOpenJoinRoomModal();
    }
  };

  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "40%",
        height: "50%",
      }}
      title={gameMode?.name}
      id="create-room-modal"
      leaveAnimation={"zoom"}
    >
      <div className="wrapper w-6/6 sm:w-full md:w-5/6  md:h-5/6 m-auto pt-10 grid grid-cols-2 gap-x-4">
        {/* CREATE */}
        <CardWrapper title="">
          <div className="flex flex-col items-center justify-between h-5/6 gap-5 md:gap-0">
            <CImage url={createPng} />
            <Button
              text="Create"
              className="play text-sm md:text-xl"
              handleClick={open}
            />
          </div>
        </CardWrapper>

        {/* JOIN */}
        <CardWrapper title="">
          <div className="flex flex-col items-center justify-between h-5/6  gap-5 md:gap-0">
            <CImage url={joinPng} />
            <Button
              text="Join"
              className="play text-sm md:text-xl"
              handleClick={handleJoinButton}
              style={{padding : '1rem !important'}}
            />
          </div>
        </CardWrapper>
      </div>
    </ModalWrapper>
  );
}

export default CreateRoomM;
