import starFish from './assets/Nature/starFish.png'
import shellFish from './assets/Nature/shell.png'
import leafTop from './assets/Nature/leaves/leave1.png'
import leafRight from './assets/Nature/leaves/leave2.png'
import leafLeft from './assets/Nature/leaves/leave3.png'
import leafBottom from './assets/Nature/leaves/leave4.png'

const CELL_TYPE = {
    normal: "NORMAL",
    safe: "SAFE",
    home: "HOME"
};

const PLAYER_TYPE = {
    red: "RED",
    blue: "BLUE",
    green: "GREEN",
    yellow: "YELLOW"
};

const TOKEN_STATUS = {
    not_started: "Not Started",
    active: "active",
    finished: "finished"
};

const GAME_STATUS = {
    not_started: "Not Started",
    animatig: "animating",
    finished: "finished",
    waiting_for_dice: "Waiting for dice",
    waiting_for_token: "Waiting for token"
};

 const NATURE_ASSET = {
    starFish,
    shellFish,
    leafTop,
    leafRight ,
    leafLeft ,
    leafBottom,
}
    

export {
    CELL_TYPE,
    PLAYER_TYPE,
    TOKEN_STATUS,
    GAME_STATUS,
    NATURE_ASSET
};