
import axios from "axios";
import { getWiningTime, showToast } from "../views/pages/Ludo/utils";
import { baseurl } from "../ApiConfig/ApiConfig";


const Axios = axios.create({
  baseURL: `${baseurl}/api/v1/ludo`,
  headers: {
    token: window.sessionStorage.getItem("token"),
  },
});


/**
 * Join Pool Api
 * @param {Object} payload 
 * @returns Promies
 */
export const service_JoinPoolApi = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
        const data = {
          ...payload,
          playerType: "PAID",
        };
        let res = await Axios({
          method: "POST",
          url: "/join/pool",
          data: data,
        });
        console.log("res.data join pool", res.data);
        const reponseCode = res.data.responseCode;
        resolve(res.data);
        
      } catch (error) {
        const reponseCode = error?.response?.data?.responseCode;
        console.log("error", error.response);
        if(reponseCode === 401) {
          showToast("error", "You are not authorized to join this pool");
        }
        reject(error);
      }
  })
}

//** save winners  */
export const service_saveWinners = async (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {startTime, endTime} = getWiningTime();
      let data = {
        ...payload,
        startTime,
        endTime
      }
      let res = await Axios({
        method: "POST",
        url: "/save/winner/record",
        data: data,
      });
      console.log("res.data save winners", res.data);
      const reponseCode = res.data.responseCode;
      if(reponseCode === 200) {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    } catch (error) {
      reject(error.response);
    }
  })
}

// ** Get game details
export const service_getGameDetails = async (poolId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await Axios({
        method: "POST",
        url: "/game/details",
        data: {poolId : poolId}
      });
      console.log("getGameDetails", res.data);
      const reponseCode = res.data.responseCode;
      if(reponseCode === 200) {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    } catch (error) {
      reject(error.response);
    }
  })
}