import React, { useEffect, useState } from 'react'
import axios from "axios"
// import { ChatDummyData } from "./ChatDummyData"
import { toast } from "react-hot-toast";

import BeatLoader from "react-spinners/BeatLoader";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const ChatBot = () => {

    const chatbotUrl = "https://chatbot.betbyheart.com/api/chatbotResponse"
    // const chatbotUrl = "http://192.168.0.135:8000/api/chatbotResponse"

    const [showChatBot, setShowChatBot] = useState(false)
    const [isZendesk, setIsZendesk] = useState(false)
    const [isTyping, setIsTyping] = useState(false)

    const [getSessionQuestion, setSessionQuestion] = useState("")


    // const q = JSON.stringify("querry")
    // const sQ = JSON.stringify("sessionQuestion")
    // const sD = JSON.stringify("sessionDate")

    const initialData = [
        {
            user: "1",
            message: "Hello there! Welcome to Bet By Heart! How can I help you today?",
            option: [
                "I am having trouble logging in",
                "I am having Transaction issues",
                "How do I get free coins?",
                "How long do withdrawals take?",
                "How does the referal system works",
            ]
        }

    ]

    const [rotate, setRotate] = useState(false)

    // storing chatData
    const [chatData, setChatData] = useState([
        {
            user: "1",
            message: "Hello there! Welcome to Bet By Heart! How can I help you today?",
            option: [
                "I am having trouble logging in",
                "I am having Transaction issues",
                "How do I get free coins?",
                "How long do withdrawals take?",
                "How does the referal system works"
            ]
        }

    ])


    // 2bb50d70-6a28-4cdc-8523-6ea8ffe2c95e;
    // 0c790eae-5fb9-416b-8d52-4a811d21ae23;



    // // loading the zendesk script
    const loadZendeskScript = () => {
        if (document.getElementById('ze-snippet')) return;
        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=2bb50d70-6a28-4cdc-8523-6ea8ffe2c95e';
        script.async = true;
        script.onload = () => {
            window.zE('messenger', 'show');
            window.zE('messenger', 'open');
        };
        document.head.appendChild(script);
    };








    // open and close the chatbot
    const handleChatBot = (val) => {
        setShowChatBot(!val)
    }



    useEffect(() => {
        localStorage.removeItem("ZD-suid")
        localStorage.removeItem("ZD-store")
        localStorage.removeItem("__zlcstore")
    }, [])


    // scroll the chatbot and hide and show the overflow in scroll
    useEffect(() => {
        const chatbot = document.getElementsByClassName('chatList')[0];
        console.log()
        if (showChatBot) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        if (chatbot) {
            setTimeout(() => {
                chatbot.scrollTop = chatbot.scrollHeight;
            }, 0);
        }
    }, [showChatBot, chatData]);



    const handleGetResponse = async (query) => {

        try {
            console.log("querry", query)
            let response = await axios.post(chatbotUrl,
                {
                    querry: query,
                    sessionQuestion: getSessionQuestion,
                    sessionDate: chatData
                }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })

            if (response.data.message === null)
                return


            setIsTyping(true)

            setTimeout(() => {
                console.log("resp", response.data)

                let newQuestionObj = {
                    user: "1",
                    message: response.data.message,
                    option: response.data.option
                };

                setIsTyping(false)
                // Use functional update to ensure you're working with the most recent state
                setChatData((prevChatData) => [
                    ...prevChatData,
                    newQuestionObj
                ]);

                if (response.data.zendesk) {
                    setIsZendesk(true)
                    setShowChatBot(false)
                }
            }, 600)






        } catch (err) {
            toast.error(err.message)
        }

    }



    // adding question toh list to display
    const handleAddUserQuestion = (question) => {
        console.log("question", getSessionQuestion)
        const getCurrentData = chatData
        let chekcLengthData = getCurrentData.at(getCurrentData.length - 1)
        if (chekcLengthData.option) {
            let hideOtherOptions = getCurrentData.at(getCurrentData.length - 1).option.filter(a => a === question)
            chatData[chatData.length - 1].option = hideOtherOptions;
        }

        handleGetResponse(question)

    };



    const handleResetSession = () => {
        setRotate(true)
        setTimeout(() => {
            setChatData([...initialData])
            setRotate(false)
        }, 2000)
    }




    return (
        <div className='chatbotDiv'>
            {
                showChatBot &&
                <div className='chatBotMainDiv'>
                    <div className='chatBotHeader'>
                        <div className='chatBotLeftHeader'>
                            <div className='chatBotHeaderProfile'>
                                <img src="/images/chatbot/profile.svg" alt="profile" />
                            </div>
                            <div className='chatBotName ml-3'>
                                <p>Smith Assistant</p>
                                <p className='chatBotOnline'>
                                    <div className='dot mr-1'></div>
                                    Online
                                </p>
                            </div>
                        </div>
                        <div className='chatBotRightHeader'>
                            <img src="/images/chatbot/reload.svg" className={`${rotate ? "rotate360" : ""}`} onClick={() => { handleResetSession() }} alt="reload" />
                        </div>
                    </div>
                    <div className='chatBotBody' >
                        <div className='chatList m-3 '>
                            {
                                chatData.map((dt, idx) => (
                                    <>
                                        {
                                            dt.user === "2" &&
                                            <div key={idx} className={`chatListQuestion userChat${dt.user} `}>
                                                {dt.message}
                                            </div>
                                        }


                                        {
                                            dt.user === "1" && (
                                                <>
                                                    <div key={idx} className={`chatListQuestion userChat${dt.user} `}>
                                                        {dt.message}
                                                    </div>
                                                    <img src="/images/chatbot/chatbotAi.svg" alt="profile" className='chatbotMsgProfile' />


                                                    <div className="chatOptions">
                                                        {
                                                            dt.option && Object.keys(dt.option).map((key, optionIdx) => (
                                                                <div
                                                                    key={optionIdx}
                                                                    className={`chatbotItemOption ${chatData.length === idx + 1 ? "chatActiveOption" : "chatInActiveOption"} `}
                                                                    onClick={() => {
                                                                        if (chatData.length === idx + 1) {

                                                                            if (chatData.length === 1 && idx + 1 === 1) {
                                                                                setSessionQuestion(dt.option[key])
                                                                            }

                                                                            handleAddUserQuestion(dt.option[key])


                                                                        }
                                                                    }}
                                                                >
                                                                    {dt.option[key]}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>

                                                </>
                                            )
                                        }



                                    </>
                                ))
                            }


                            {
                                isTyping && (
                                    <div className='botIsTyping'>
                                        <div className='botLoader'>
                                            <BeatLoader
                                                size={7}
                                                speedMultiplier={0.3}
                                                color='white'
                                            />
                                        </div>
                                    </div>
                                )
                            }


                        </div>


                    </div>
                </div>
            }
            {
                isZendesk && (loadZendeskScript())
            }
    
            {
                !isZendesk && (
                    <>
                        <img src={`${showChatBot ? "/images/chatbot/chatboticonOpen.svg" : "/images/chatbot/chatboticonClose.svg"}`} className='chatbotHandleButton' alt="chatboticon" width={50} onClick={() => { handleChatBot(showChatBot) }} />
                    </>
                )
            }
           
            
        </div>
    )
}

export default ChatBot