// // //////////------Binance testnet---------////////

// export const NetworkContextName = "Smart Chain - Testnet";
// export const ACTIVE_NETWORK = 97;
// export const RPC_URL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
// export const tokenContract = "0x32ec18298313Dc879e08B43EB67aeB94779C9Bc6"; //BNB testnet //BUSD (Owner ..b73)
// export const referralClaimContract =
//   "0x1Ee3C7f2Cb4Bb168E933c9C0eaa61A12FCF74CFf"; //BNB testnet //BUSD (Owner ..b73)

// export const lotteryContract = "0xFb6E083A87CB0f91404D2B26110084D4B025DBA9"; //BNB testnet //BUSD (Owner ..b73)
// export const chainTokenAddress = "0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06"; //BNB testnet all same

// export const multiSenderContractAddress =
//   "0xB821B07C4d2910EF24cf701d77fc4Cd42d4EC4F0"; //BNB testnet //BUSD
// export const currencyUnit = "USDC";
// export const virtualCurrency = "USD";
// export const derbyBetMinimumPrice = "0.1";
// export const contractDecimals = 18; ///USDC==6 digit & BUSD==18digit

// //////////////new centralised//////////
// export const getBalanceContract = "0x16227D60f7a0e586C66B005219dfc887D13C9531"; //BNB testnet ///BUSD
// export const randomContractAddress =
//   "0x18729174f225344AAE696662815048fD4C676a86"; //BNB testnet //BUSD

// export const lotterrCretionPrice = {
//   min: 1,
//   max: 50,
// };

// export const lotteryNumberrange = {
//   min: 1000000,
//   max: 1999999,
// };

// export const NetworkDetails = [
//   {
//     chainId: "0x61",
//     chainName: "Smart Chain - Testnet",
//     nativeCurrency: {
//       name: "BNB",
//       symbol: "BNB",
//       decimals: 18,
//     },
//     rpcUrls: [RPC_URL],
//     blockExplorerUrls: ["https://testnet.bscscan.com/"],
//   },
// ];

/////////-------Binance mainnet--------////////

export const NetworkContextName = "Smart Chain";
export const ACTIVE_NETWORK = 56;
export const RPC_URL = "https://bsc-dataseed1.binance.org/"; //mainnet
export const tokenContract = "0xE5a2D7d5f140B1cf9310558F27cC574902257DE7"; ///mainnet USDC (Owner ..DC9)
// export const tokenContract = "0x388e4A6a5b5d9877c03E5696D05c731FB106dbb0"; ///mainnet anil USDC
export const referralClaimContract =
  "0xF1BfBEcC577fbe7fFCa78AF6074d88F34aAbBC93"; //BNB mainnet //USDC (Owner ..DC9)

export const lotteryContract = "0x22F8362638a85eD1Df82855Ec2365D0B1fd3C002"; //BNB mainnet USDC anil sir  (Owner ..DC9)
export const chainTokenAddress = "0x404460C6A5EdE2D891e8297795264fDe62ADBB75"; //BNB mainnet always same
export const randomContractAddress =
  "0xCc91F5Ef559d5461f8991814a3bd32AE95911d1F"; //BNB mainnet USDC anil sir

////////////new centralised//////////
export const getBalanceContract = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d"; ///mainnet USDC
export const multiSenderContractAddress =
  "0x7D0775f230E44fF030FEbE1c534247b9Dd3706B2"; //BNB testnet //BUSD

export const currencyUnit = "USDC";
export const virtualCurrency = "USD";
export const derbyBetMinimumPrice = "1";
export const contractDecimals = 18;

export const lotterrCretionPrice = {
  min: 1,
  max: 50,
};

export const lotteryNumberrange = {
  min: 1000000,
  max: 1999999,
};

export const NetworkDetails = [
  {
    chainId: "0x38",
    chainName: "Smart Chain",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
];
