export const SOCKET_EVENTS = {
  CONNECTION: "connection",
  DISCONNECT: "disconnect",
  RECONNECT: "reconnect",
  CRETAE_ROOM: "create-room",
  CREATE_QUICK: "create-quick",
  JOIN_GAME: "join-game",
  LEAVE_GAME: "leave-game",
  PLAYER_LIST: "PlayerList",
  PLAYER_ENTER: "PlayerEnter",
  PLAYER_LEFT: "PlayerLeft",
  GET_COLORS : 'get-colors',
  ROOM_LIST: "room-list",
  GAME_START: "game-start",
  GAME_STATE: "game-state",
  GAME_END: "game-end",
  ROLL_DICE: "roll-dice",
  PLAYER_EXIT: "player-exit",
  RESUME: "resume",
  ERROR: "error",
  INFO : 'info',
  GAME_NOT_FOUND : 'game_not_found'
};
  export const GAME_EVENTS = {
    UPDATE_TURN : 'update:turn',
    UPDATE_MOVE : 'update:move',
    TIMER_END : 'timer:end',
    GAME_RESULT : 'game:result',
    PLAYER_EVENT : 'player:result',
    REMATCH_REQUEST: "rematch:request",
    REMATCH_ACCEPT: "rematch:accept",
    GET_WINNERS : "get:winners",
    SEND_MESSAGE : "send:message",
  };
  
  