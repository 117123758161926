/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import CardWrapper from "../CardWrapper";

// boards
import CImage from "../../../../components/Img/CImage";
import natureBoard from "../../../../assets/natureBoard.png";
import defaultBoard from "../../../../assets/defaultBoad.png";
import doneText from "../../../../assets/done.svg";
import { CheckIcon } from "./SelectGameModal";
import FlexWrapper from "../../../../components/Wrapper/FlexWrapper";
import Text from "../../../../components/Wrapper/Text";
import Button from "../../../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { setGameObj } from "../../../../feature/slice/gameSlice";
import {
  createRoom,
  
  localSetRoomProperties,
  setRoomProperties,
} from "../../../../constants/socketKeys";
import {
  delay,
  generateRandomRoomName,
  generateRoomCode,
} from "../../../../utils";
import { publish } from "../../../../CustomEvents/event";
import { resetSessionStorage, setSessionStorageItem } from "../../../../utils/sessionStorageUtils";


function SelectBoardM({
  modalShow,
  onClose,
  setModalShow,
  onOpenJoinPlayerModalShow,
  setRoomCode,
  setRoomPassword,
}) {
  const [boards, setBoard] = useState([
    {
      title: "Default",
      board: "default",
      img: defaultBoard,
      selected: true,
    },
    {
      title: "Nature",
      board: "nature",
      img: natureBoard,
      selected: false,
    },
  ]);
  const dispatch = useDispatch();
  const { gameMode, gameObj } = useSelector((state) => state.game);

  // TODO - Handle Create room
  const done = () => {
    //  //;
    // ** publish create room
    const roomCode = generateRoomCode(6);
    const roomPassword = generateRandomRoomName("password");

    const visible = gameMode?.name === "Private" ? false : true;

    // **Set room code
    setRoomCode(roomCode);
    //** set room passwrod */
    setRoomPassword(roomPassword);
    var roomCreation = {
      roomId: roomCode,
      maxPlayers: gameObj?.inGamePlayer,
      ttl: 50,
      isVisible: visible,
      roomPassword: "abc123",
      playerName : 'user00' + Math.floor(Math.random() * 100),
      color : gameObj?.token,
      boardType : gameObj?.selectedBoard,
      roomAmount: gameObj?.feeAmmount
    };

    console.log("Creating room ", roomCreation);
    
    // ** publish create room
    publish(createRoom, roomCreation);

    // delay(() => {
    //   publish(joinRoom, {
    //     roomname: roomCode,
    //     nickname: "user00" + `${Math.floor(Math.random() * 100)}`,
    //     roomPassword: "abc123",
    //   });
    // },10)

    // **open private room lobby modal
    onOpenJoinPlayerModalShow();

    // setModalShow
    setModalShow(false);

    // ** navigate into game

    //** clear out session */
    setSessionStorageItem("gameObj", null);

    resetSessionStorage();
    
    // ** onClse modal
    onClose();
  };

  // TODO - handle theme select
  const SelectTheme = (board) => {
    dispatch(setGameObj({ selectedBoard: board }));
    setBoard((prev) => {
      return prev.map((val) => {
        if (val.board === board) {
          val.selected = true;
        } else {
          val.selected = false;
        }
        return val;
      });
    });
  };

  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "60%",
        height: "65%",
      }}
      title="Themes"
      id="Themes"
    >
      <div className="themes w-5/6 h-5/6 m-auto pt-2 lg:pt-10 grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2">
        {/* CREATE */}
        {boards.map(({ title, img, selected, board }) => (
          <CardWrapper
            title={title}
            key={title}
            customClass="h-[15rem] sm:h-[15rem]"
          >
            <div className="board-image flex flex-col items-center justify-between">
              <CImage
                url={img}
                coustomClass="scale-95 sm:w-[15rem] lg:w-auto board-theme"
              />
            </div>
            <FlexWrapper className={"select-wrapper"}>
              <div
                className={`select-button w-8 h-8 mt-2 border-2 ${
                  selected
                    ? "border-yellow-500 shadow-md shadow-yellow-500"
                    : ""
                } rounded-full cursor-pointer`}
                onClick={() => SelectTheme(board)}
              >
                {selected ? <CheckIcon /> : null}
              </div>
              <Text title="Select" color="text-white text-2xl" />
            </FlexWrapper>
          </CardWrapper>
        ))}
      </div>
      <div className="flex items-center justify-center lg:justify-end h-1/6 lg:h-auto w-5/6 m-auto">
        <Button
          handleClick={done}
          text={<img src={doneText} alt={doneText} className="w-16" />}
          className="py-4"
        />
      </div>
    </ModalWrapper>
  );
}
export default SelectBoardM;
