import React, { useEffect, useState } from 'react'
import ModalWrapper from '../../../../components/Modal/ModalWrapper';
import CoinCounterCard from '../CoinCounterCard';
import { useRoomListing } from "../../../../hooks/useRoomListing";
import {
  getRandomColorByExistingColor,
  showToast,
} from "../../../../utils";

import { useHistory } from "react-router-dom";
import { getSessionStorageItem, resetSessionStorage, } from "../../../../utils/sessionStorageUtils";
import {
  setGameObj,
  setSingleLobbyData,
} from "../../../../feature/slice/gameSlice";
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../../../socket/socket';
import { SOCKET_EVENTS } from '../../../../socket/keys';
import Spinner from "../../../../components/Spinner/Spinner";


function QuickTypeModal({
    modalShow,
    onClose,
    creating,
    setCreating,
    balance
}) {
    const {gameObj} = useSelector(state => state.game);
    const [isClickable, setClickable] = useState(false);
    const [quickCreatTime, setquickCreatTime] = useState(10000);
   

   useEffect(() => {
    if(modalShow) {
      let interval = setInterval(() => {
        if(quickCreatTime <= 0) {
          clearInterval(interval);
          setClickable(true);
        } else {
          setquickCreatTime(prev => prev - 1000)
        }
      },1000)
      return () => clearInterval(interval)
    }
   },[modalShow,quickCreatTime])



  // ** dispatch state 
  // ** generate random strings
  // const dispatchState = () => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       const token = getRandomColor();
  //       const inGamePlayer = getRandomPlayerRoomCount();
  //       const selectedBoard = getRandomeBoard();
  //       const roomName = generateRandomRoomName();
  //       const roomAmmount = gameObj?.feeAmmount;

  //       // ** update game object
  //       dispatch(
  //         setGameObj({
  //           token: token,
  //           inGamePlayer: inGamePlayer,
  //           feeType: "coin",
  //           feeAmmount: roomAmmount,
  //           selectedBoard: selectedBoard,
  //         })
  //       );

  //       resolve({
  //         token: token,
  //         inGamePlayer: inGamePlayer,
  //         selectedBoard: selectedBoard,
  //         roomName,
  //         roomAmmount: roomAmmount
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // };

  // ** create game
  const createNewGame = () => {
    // if(!isClickable) return;

    if(+balance < 1) {
      showToast('error', "You dont have enough amount to play game");
      return;
   }

    setCreating(true);
    socket.emit(SOCKET_EVENTS.CREATE_QUICK,
      {
       roomAmount:gameObj?.feeAmmount,
       userId : localStorage.getItem('userID'),
       playerName : localStorage.getItem('userName')
     });
    //** clear out session */
    resetSessionStorage();
  };

  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "20%",
        height: "35%",
      }}
      title="Select Price"
      enterAnimation="zoom"
      leaveAnimation="zoom"
      id="select-price-modal"
    >
      <div className="py-2">
        <CoinCounterCard />
      </div>
      <div className="py-2 flex justify-center">
        {creating ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <button
            onClick={createNewGame}
            class="bg-[#ffc226] hover:bg-[#d3a736] text-white text-shadow text-sm lg:text-md  font-bold py-2 px-4 rounded"
          >
            {/* {isClickable
              ? "Start"
              : `Wait for ${quickCreatTime / 1000}s to create new game`} */}
              Play
          </button>
        )}
      </div>
    </ModalWrapper>
  );
}

export default QuickTypeModal
