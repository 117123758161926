import React, { useEffect, useState } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import { TOKEN } from "../../../../utils/tokenConstant";
import Token from "../TokenSelect/Token";
import { handleUpdateState } from "../../../helper";
import Button from "../../../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../../../../socket/socket";
import { joinRoom, SocketjoinRoom } from "../../../../constants/socketKeys";
import { publish } from "../../../../CustomEvents/event";
import { useHistory } from "react-router-dom";
import { setGameObj, } from "../../../../feature/slice/gameSlice";
import {  resetSessionStorage } from "../../../../utils/sessionStorageUtils";
import { showToast } from "../../../../utils";
import Spinner from "../../../../components/Spinner/Spinner";
import { SOCKET_EVENTS } from "../../../../socket/keys";

function SelectPawns({ modalShow, onClose }) {
  const [selected, setSelected] = useState(false);
  const [tokens, setTokens] = useState([
    {
      color: "green",
      selected: false,
      tokenImage: TOKEN["green"],
      hash: "#00FF19",
    },
    {
      color: "blue",
      selected: false,
      tokenImage: TOKEN["blue"],
      hash: "#00A3FF",
    },
    {
      color: "yellow",
      selected: false,
      tokenImage: TOKEN["yellow"],
      hash: "#F2B901",
    },
    {
      color: "red",
      selected: false,
      tokenImage: TOKEN["red"],
      hash: "#FF0000",
    },
  ]);
  // const [color,setSelectedColor] = useState("");
  const {singleLobbyData,gameMode } = useSelector((state) => state.game);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [color,setcolor] = useState(null);

   
  useEffect(() => {
     if(singleLobbyData) {
       socket.emit(SOCKET_EVENTS.GET_COLORS,{roomId : singleLobbyData?.roomId})
     }
  },[singleLobbyData]);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.GET_COLORS, ({tokens}) => {
      console.log("SOCKET_EVENTS.GET_COLORS",tokens);
      setTokens(prev => prev.filter(token => tokens.includes(token.color)));
      setLoading(false);
    });
    return () => socket.off(SOCKET_EVENTS.GET_COLORS);
 },[]);



  // ** haldle Select Color **
  const SelectColor = (token) => {
    setSelected(true);
    setcolor(token.color);
    setTokens((prev) => handleUpdateState(prev, token, "token"));
  };

  //** handle join rooms */
  const handleJoinRoom = () => {
    if (!selected) {
      showToast("error", "Select your token first!");
      return;
    }

    const roomId = singleLobbyData?.roomId;
    const joinRoomData = {
      roomId,
      playerName: "user00" + `${Math.floor(Math.random() * 100)}`,
      color: color,
      userId : localStorage.getItem('userID')
    };

    publish(joinRoom, joinRoomData);

    //** clear out session */
    resetSessionStorage();
  };

  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "30%",
        height: "35%",
      }}
      title="Select Color"
      enterAnimation="zoom"
      leaveAnimation="zoom"
      id="select-pawn-modal"
    >
      <div className="px-2 py-5 flex w-7/12 m-auto justify-around">
        {
          loading ? <Spinner /> : ( 
            <>
              {tokens.map((token) => (
                <Token token={token} key={token.color} SelectColor={SelectColor} />
              ))}
            </>
          )
        }
       
      </div>
      <div className="py-2 w-full flex justify-center items-center">
        <Button
          text="Start"
          className="text-sm lg:text-xl text-yellow-900"
          handleClick={handleJoinRoom}
        />
      </div>
    </ModalWrapper>
  );
}

export default SelectPawns;
